import './assets/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import * as Sentry from '@sentry/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faClock,
  faBatteryQuarter,
  faCheckCircle,
  faMapLocationDot,
  faRectangleXmark,
  faBars,
  faPlug,
  faCircleCheck,
  faMoneyBill,
  faAngleDown,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faGoogle,
  faWhatsapp,
  faCcMastercard
} from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import FBSignInButton from 'vue-facebook-signin-button'
import VueTheMask from 'vue-the-mask'
import { createBootstrap } from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { HFaceBookLogin } from '@healerlab/vue3-facebook-login'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueScrollTo from 'vue-scrollto'
import vue3GoogleLogin from 'vue3-google-login'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(createBootstrap())
app.use(i18n)
app.use(router)
app.use(FBSignInButton)
app.use(VueTheMask)
app.use(VueScrollTo)

app.use(VueGtag, {
  config: { id: import.meta.env.VITE_GOOGLE_GTAG_ID }
})

library.add(
  fas,
  faCheckCircle,
  faClock,
  faBatteryQuarter,
  faFacebook,
  faGoogle,
  faWhatsapp,
  faMapLocationDot,
  faRectangleXmark,
  faBars,
  faCircleCheck,
  faCcMastercard,
  faMoneyBill,
  faAngleDown,
  faPlug,
  faXmark
)
app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  }
})
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('HFaceBookLogin', HFaceBookLogin)
app.mount('#app')
