<div>
  <div>
    <a class="btn-close-map-background" @click="$router.go(-1)">
      <font-awesome-icon class="btn-close-map" icon="fa-solid fa-rectangle-xmark" />
    </a>
  </div>
  <div class="terminal-type-selection">
    <b-dropdown text="Primary" variant="bg-white" class="m-2">
      <template #button-content>
        <span v-text="$t('map.terminal_type')"></span><strong>{{ terminalType }}</strong>
      </template>
      <b-dropdown-item @click="filterPhone()"
        ><strong><span v-text="$t('map.phone')"></span></strong
      ></b-dropdown-item>
      <b-dropdown-item @click="filterEbike()"
        ><strong><span v-text="$t('map.ebike')"></span></strong
      ></b-dropdown-item>
      <b-dropdown-item @click="filterNone()"
        ><strong><span v-text="$t('map.all')"></span></strong
      ></b-dropdown-item>
    </b-dropdown>
  </div>
  <div>
    <GMapMap
      class="map-display"
      ref="map"
      :options="mapOptions"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      @click="closeMarkerInfo()"
      @zoom_changed="zoomChanged"
      @dragend="dragEnd"
    >
      <div>
        <div v-for="terminal in terminals">
          <GMapMarker
            class="gmap-marker"
            v-if="terminal.show"
            :key="terminal.id"
            :position="latlng(terminal)"
            @click="onMarkerClick(terminal)"
            :icon="iconGen(terminal)"
            :label="labelName(terminal)"
          >
          </GMapMarker>
          <GMapMarker
            class="gmap-marker"
            v-if="terminal.show"
            :key="terminal.id + 10000"
            :position="latlng(terminal)"
            :icon="iconGenSecondary(terminal)"
            :label="labelNum(terminal)"
          >
          </GMapMarker>
        </div>
        <GMapMarker
          class="gmap-marker"
          :position="myLocation"
          :icon="{
              url: icon.myLocation,
              anchor: {
                x: 10,
                y: 10
              },
              scaledSize: { width: 20, height: 20 },
              title: $t('map.my_location')
            }"
        >
        </GMapMarker>
      </div>
    </GMapMap>
  </div>
  <div class="marker-info" v-if="selectedTerminal !== null">
    <div class="col">
      <div class="column column-btn">
        <a :href="urlGMaps" class="btn btn-sm btn-maps" role="button">
          <font-awesome-icon class="map-location-icon" icon="fa-solid fa-map-location-dot" />
        </a>
      </div>
      <div class="column column-description">
        <b>{{ selectedTerminal.name }}</b>
        <br /><span class="grey-text">{{ selectedTerminal.address }}</span>
        <div v-if="selectedTerminal.close_hour && selectedTerminal.open_hour">
          <div :open="open">
            <span
              :class="open 
                ? 'blue-text'
                : 'grey-text'"
            >
              {{ open ? $t('map.place_open') : $t('map.place_closed') }}</span
            >
            <span class="grey-text">
              - {{ open ? $t('map.close_hour') : $t('map.open_hour') }}
              <span v-if="open"
                >{{ $t('map.hour') === 'pt' ? selectedTerminal.close_hour_string :
                selectedTerminal.close_hour_string_en}}</span
              >
              <span v-else
                >{{ $t('map.hour') === 'pt' ? selectedTerminal.open_hour_string :
                selectedTerminal.open_hour_string_en}}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
