<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <div v-if="cableSelectionEnable">
      <b-card class="select-card">
        <Transition
          name="fade-element"
          mode="out-in"
        >
          <!-- Image transition -->
          <div
            v-if="cableSelectionEnable"
            key="image"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <p
              class="page-title h4 text-uppercase"
              v-text="$t('home.take.take_title')"
            />
            <p
              class="page-text fw-normal"
              v-text="$t('home.take.take_info')"
            />
          </div>
        </Transition>
        <Transition
          name="fade-element"
          mode="out-in"
        >
          <!-- Image transition -->
          <BContainer
            key="text"
            class="bv-example-row mt-4"
          >
            <BRow>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_lightning_button"
                  class="cable-img"
                  src="@/assets/images/cables/apple.png"
                  alt="imagem conector Apple Lightning"
                  @click="selectCable(CABLE_TYPE_LIGHTNING)"
                >
                <p class="h6 page-text">
                  Apple Lightning
                </p>
              </BCol>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_micro_usb_button"
                  class="cable-img"
                  src="@/assets/images/cables/micro.png"
                  alt="imagem conector Micro USB"
                  @click="selectCable(CABLE_TYPE_MICRO)"
                >
                <p class="h6 page-text">
                  Micro USB
                </p>
              </BCol>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_type_c_button"
                  class="cable-img"
                  src="@/assets/images/cables/c.png"
                  alt="imagem conector USB tipo C"
                  @click="selectCable(CABLE_TYPE_USB)"
                >
                <p class="h6 page-text">
                  USB-C
                </p>
              </BCol>
            </BRow>
          </BContainer>
        </Transition>
      </b-card>
    </div>
  </Transition>
</template>

<script>
import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USB } from '@/constants.js'

export default {
  name: 'SelectCableType',
  props: {
    cableSelectionEnable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cableType: null,
      CABLE_TYPE_LIGHTNING,
      CABLE_TYPE_MICRO,
      CABLE_TYPE_USB
    }
  },
  methods: {
    selectCable (type) {
      this.cableType = type
      this.$emit('cableType', this.cableType)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.cable-img {
  width: 100%;
  border: 2px solid #424242;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 1px solid #424242;

  &:hover {
    border-color: $color-energy-orange;
  }
}

.cable-type {
  text-align: center !important;
  font-size: 12px;
}

.cableActive {
  border: 3px solid $color-energy-orange !important;
}
</style>
