export default function getEventsMiniComponents(component) {
  switch (component) {
    case 'map':
      return {
        clicked: () => this.$router.push({ name: 'maps' })
      }
    case 'priceTable':
      return {
        clicked: () => {
          return this.scrollToPriceTable()
        }
      }
    case 'userCreditCard':
      return {
        clicked: () => {
          return this.selectBigBoxComponent('deleteCreditCard')
        }
      }
    case 'cableType':
      return {
        clicked: () => {
          this.cableType = null
          this.selectBigBoxComponent()
          return
        }
      }
    case 'token':
      return {
        clicked: () => {
          this.token = null
          this.transactionId = null
          this.pointName = null
        }
      }
    default:
      return console.log('Component not found')
  }
}
