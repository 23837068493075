/* global FB */
/* global google */
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import fbIcon from '@/assets/images/facebook_icon.png'
import PriceTableModal from '@/components/PriceTableModal.vue'
import AppVersion from '@/components/AppVersion.vue'
import { decodeCredential } from 'vue3-google-login'

export default {
  name: 'login-social',
  components: {
    PriceTableModal,
    AppVersion
  },
  props: [],
  data() {
    return {
      fb_icon: fbIcon,
      locale: '',
      showPriceTableModal: false
    }
  },
  computed: {},
  mounted: function () {
    window.fbAsyncInit = function () {
      FB.init({
        appId: import.meta.env.VITE_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        status: true,
        version: import.meta.env.VITE_FACEBOOK_VERSION
      })
    }
    ;(function (d, s, id) {
      let js
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  },
  methods: {
    callback(response) {
      this.googleLoginResponse(response)
    },
    async googleLoginResponse(response) {
      const responsePayload = decodeCredential(response.credential)
      try {
        const responseLogin = await axios.post(import.meta.env.VITE_MOBILE_API + 'social/login', {
          email: responsePayload.email,
          name: responsePayload.name,
          social_id: responsePayload.sub,
          from_social: 'google',
          auto_confirm: true
        })
        if (responseLogin.data.success) {
          localStorage.setItem('token', responseLogin.data.token)
          axios.defaults.headers.common['Authorization'] = responseLogin.data.token
          this.$router.push({
            name: 'home',
            params: {}
          })
        } else {
          Sentry.captureMessage(
            `Failed to google login ${JSON.stringify(responseLogin)}, calling #google_login()`
          )
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    handleShowPriceTableModal(data) {
      this.showPriceTableModal = data
    },

    handleCampaign: function () {
      const campaignUrl = sessionStorage.getItem('campaignUrl')
      if (campaignUrl) {
        this.$router.push(campaignUrl)
      }
    },
    onFacebookSignInSuccess(response) {
      if (response.authResponse) {
        if (
          response &&
          response.authResponse &&
          response.authResponse.grantedScopes &&
          response.authResponse.grantedScopes.includes('email')
        ) {
          this.handleFacebookUserData(response.authResponse.userID)
        } else {
          this.reRequestEmailPermission()
        }
      }
    },

    handleFacebookUserData(userID) {
      FB.api('me?fields=name,email', (user) => {
        if (user && user.error) {
          Sentry.captureMessage(
            `Error authorizating facebook user ${JSON.stringify(user)}`, 'warning'
          )
          return
        }
        axios
          .post(import.meta.env.VITE_MOBILE_API + 'social/login', {
            name: user.name,
            email: user.email,
            social_id: userID,
            from_social: 'facebook',
            auto_confirm: true
          })
          .then((res) => {
            if (res.data.success) {
              localStorage.setItem('token', res.data.token)
              axios.defaults.headers.common['Authorization'] = res.data.token
              this.$router.push({ name: 'home', params: {} })
            } else {
              Sentry.captureMessage(
                `Failed to login facebook ${JSON.stringify(res.data)}, calling #handleFacebookUserData()`, 'warning'
              )
            }
          })
          .catch((error) => {
            Sentry.captureException(error)
          })
      })
    },

    reRequestEmailPermission() {
      FB.login(
        (response) => {
          if (response && response.authResponse && response.authResponse.userID) {
            this.handleFacebookUserData(response.authResponse.userID)
          } else {
            console.error('User declined email permission again.')
          }
        },
        { scope: 'email', auth_type: 'rerequest' }
      )
    },

    onFacebookError: function (response) {
      Sentry.captureMessage(
        `Failed to facebook login ${JSON.stringify(response)}, calling #facebook_login()`
      )
    }
  }
}
