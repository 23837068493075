// /* global fbq */
import axios from 'axios'
import API from '@/services/api'
import getPointByToken from '@/services/getPointName'
import SelectCableType from '@/components/SelectCableType.vue'
import TokenSelection from '@/components/TokenSelection.vue'
import CreditCardRegistration from '@/components/CreditCardRegistration.vue'
import DefaultTile from '@/components/DefaultTile.vue'
import UserDefaults from '@/components/UserDefaults.vue'
import SideBar from '@/components/SideBar.vue'
import EvaluationBox from '@/components/EvaluationBox.vue'
import PriceTable from '@/components/PriceTable.vue'
import RentCheckout from '@/components/RentCheckout.vue'
import RentSuccess from '@/components/RentSuccess.vue'
import { isProxy, toRaw } from 'vue'
import getPropsBigComponents from '@/views/home/service/bigBoxProps'
import getEventsBigComponents from '@/views/home/service/bigBoxEvents'
import getPropsMiniComponents from './service/miniBoxProps'
import getEventsMiniComponents from './service/miniBoxEvents'
import rentProcessingMethods from './service/rentProcessingMethods'
import CheckOutScreen from '@/views/checkout-screen/CheckOutScreen.vue'
import UAParser from 'ua-parser-js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import {
  TAKE_BATTERY_SUCCESS,
  TAKE_BATTERY_ERROR,
  BIG_BOX_COMPONENT_LIST,
  MINI_BOX_COMPONENT_LIST
} from '@/constants.js'
import { VERSION } from '@/config'

import '@/main.scss'

const TERMINAL_DISTANCE_UNKNOW = 0
const TERMINAL_DISTANCE_NEAR = 1
const TERMINAL_DISTANCE_FAR = 2

const USER_INFO_SHARING_PERMISSION_DENIED = 1
const USER_INFO_SHARING_PERMISSION_GRANTED = 2
const USER_INFO_SHARING_PERMISSION_GRANTED_ALWAYS = 3

const CREDIT_CARD_RESERVATION_DO_NOT_ALLOW = 1
const CREDIT_CARD_RESERVATION_ALLOW = 2
const CREDIT_CARD_RESERVATION_ALLOW_ALWAYS = 3

export default {
  name: 'home',
  components: {
    SelectCableType,
    TokenSelection,
    CreditCardRegistration,
    SideBar,
    DefaultTile,
    EvaluationBox,
    PriceTable,
    UserDefaults,
    RentCheckout,
    RentSuccess,
    CheckOutScreen
  },
  props: [],
  data() {
    return {
      campaign: null,
      cableType: null,
      user: null,
      plans: null,
      user_devices: null,
      lastTransaction: null,
      position: null,
      terminal: {
        campaign: false,
        far: false
      },
      user_permission: {
        info_sharing: null,
        info_sharing_anyway: false,
        credit_reservation: null,
        credit_reservation_always: false
      },
      qr_code_on: false,
      takeType: 'terminal',
      modal_error: '',
      qr_type: null,
      name: '',
      email: '',
      plan: '',
      latitude: null,
      longitude: null,
      precision: null,
      batteryLevel: null,
      batteryCharging: null,
      batteryTimeRemaining: null,
      isBatterySupported: 'getBattery' in navigator,
      transactionId: null,
      token: '',
      infoSharing: false,
      creditCardReservationAlways: false,
      infoSharingAlways: false,
      showCreditCardRegistration: false,
      showPBox: false,
      pBoxClose: null,
      pBoxTitle: null,
      pBoxText: null,
      showPBoxCancelButton: null,
      pBoxCancelButtonText: null,
      showPBoxConfirmButton: null,
      pBoxConfirmButtonText: null,
      showCheckboxPBox: null,
      pBoxCheckBoxText: null,
      showPBoxSpinner: null,
      pBoxCheckBoxValue: null,
      pBoxButtonConfirmation: null,
      pBoxButtonCancel: null,
      pBoxSecondsToClose: null,
      editCableType: true,
      editCreditCardSelection: true,
      editTokenSelection: true,
      showPriceTable: false,
      pBoxShowLink: false,
      UUID: null,
      showEvaluationTags: false,
      pointName: null,
      pointId: null,
      pointIdQr: null,
      pointIdTokenSelection: null,
      newDeviceSaved: false,
      geoToSaveDeviceStatus: false,
      isFirstRequest: true,
      showSpinner: false,
      showCreditCardDeleteBox: false,
      showBigBoxComponent: true,
      bigBoxComponentList: BIG_BOX_COMPONENT_LIST,
      miniBoxComponentList: MINI_BOX_COMPONENT_LIST,
      selectedBigBoxComponent: null,
      showMiniBoxComponentList: [MINI_BOX_COMPONENT_LIST[0], MINI_BOX_COMPONENT_LIST[1]]
    }
  },
  created() {
    if (this.isBatterySupported) {
      navigator.getBattery().then((battery) => {
        this.updateBatteryStatus(battery)
        battery.addEventListener('levelchange', () => {
          this.updateBatteryStatus(battery)
        })
        battery.addEventListener('chargingchange', () => {
          this.updateBatteryStatus(battery)
        })
      })
    }
    Object.keys(rentProcessingMethods).forEach((method) => {
      this[method] = rentProcessingMethods[method].bind(this)
    })
  },
  async mounted() {
    let c = sessionStorage.getItem('campaign')
    this.token = localStorage.getItem('terminal_token')
    this.pointName = localStorage.getItem('point_name')
    this.pointIdQr = localStorage.getItem('point_id')
    localStorage.removeItem('point_id')

    if (c) {
      this.campaign = c
    }

    this.UUID = await this.getUUID()
    API.defaults.headers.common['X-Trace-ID'] = this.UUID

    try {
      this.sendDeviceInfo()
    } catch (error) {
      console.log(error)
    }

    try {
      const position = await this.getLocation()
      this.position = toRaw(position)

      if (!this.pointIdQr) {
        const point = await this.getNearPointInARange()
        if (point && point[0]) {
          this.pointIdGeolocation = point[0].id
        }
      }

      console.log('geolocation: ', this.position)
    } catch (error) {
      console.error('Error on geolocation:', error)
    }

    try {
      await this.getUser(this.position)

      if (this.user) {
        this.name = this.user.name
        this.email = this.user.email
        this.plan = this.user.plan.name
        this.user_devices = this.user.devices
        await this.saveBatteryStatus(this.position)

        this.updateLastTransaction()
      }

      setInterval(() => {
        this.updateLastTransaction()
      }, 60000)

      this.getPlans()
    } catch (error) {
      console.log(error)
    }

    this.selectBigBoxComponent()
  },
  watch: {
    showPBox(newValue) {
      this.editCableType = !newValue
      this.editCreditCardSelection = !newValue
      this.editTokenSelection = !newValue
    },
    selectedBigBoxComponent(newValue) {
      this.selectedBigBoxComponent = newValue
    }
  },

  methods: {
    async getUUID() {
      try {
        // Load FingerprintJS
        const fpPromise = FingerprintJS.load()
        const fp = await fpPromise
        const result = await fp.get()
        return result.visitorId
      } catch (error) {
        console.error('Error fetching UUID:', error)
        return null
      }
    },
    async getNearPointInARange() {
      const response = await axios.post(import.meta.env.VITE_MOBILE_API + 'points/near', {
        lat: this.position.lat,
        lng: this.position.lng,
        range: 500
      })
      return response.data
    },
    getPropsBigComponents(component) {
      return getPropsBigComponents.call(this, component)
    },
    getEventsBigComponents(component) {
      return getEventsBigComponents.call(this, component)
    },
    getPropsMiniComponents(component) {
      return getPropsMiniComponents.call(this, component, this.user, this.cableType)
    },
    getEventsMiniComponents(component) {
      return getEventsMiniComponents.call(this, component)
    },
    addToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index === -1) {
        const componentToAdd = this.miniBoxComponentList.find((c) => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    removeFromMiniBoxComponentList(component, onlyRemove = false) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index !== -1) {
        this.showMiniBoxComponentList.splice(index, 1)
      }
    },
    addOrRemoveToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index !== -1) {
        this.showMiniBoxComponentList.splice(index, 1)
      } else {
        const componentToAdd = this.miniBoxComponentList.find((c) => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    async selectBigBoxComponent(component = null) {
      if (!component) {
        if (this.user && !this.user.default_card && this.user.is_user_on_demand) {
          this.selectedBigBoxComponent = this.bigBoxComponentList.find(
            (bigBoxComponent) => bigBoxComponent.reference === 'creditCardRegistration'
          )
          this.removeFromMiniBoxComponentList('userCreditCard')
          return
        }

        if (this.user && this.user.default_card && this.user.is_user_on_demand) {
          this.addToMiniBoxComponentList('userCreditCard')
        }
        if (this.user && (this.cableType === null || this.cableType === undefined)) {
          this.selectedBigBoxComponent = this.bigBoxComponentList.find(
            (bigBoxComponent) => bigBoxComponent.reference === 'selectCableType'
          )
          return
        }

        if (this.user && this.cableType !== null && this.cableType !== undefined) {
          this.addToMiniBoxComponentList('cableType')
        }

        if (this.user && (!this.token || !this.transactionId || !this.pointName)) {
          this.selectedBigBoxComponent = this.bigBoxComponentList.find(
            (bigBoxComponent) => bigBoxComponent.reference === 'tokenSelection'
          )
          return
        }

        if (this.user && this.token && this.transactionId && this.pointName) {
          this.addToMiniBoxComponentList('token')
          this.selectedBigBoxComponent = null

          if (!this.isFirstRequest) {
            return
          }
          try {
            this.isFirstRequest = false
            const position = await this.getLocation()
            await this.saveBatteryStatus(position)
          } catch (error) {
            console.error(error)
          }
        }
        return
      }

      if (this.selectedBigBoxComponent && component === this.selectedBigBoxComponent.reference) {
        this.selectedBigBoxComponent = null
        return
      }
      this.selectedBigBoxComponent = this.bigBoxComponentList.find(
        (bigBoxComponent) => bigBoxComponent.reference === component
      )
    },

    async saveBatteryStatus(position) {
      const device = this.user.devices.find((device) => device.uuid === this.UUID)
      if (
        device &&
        (!device.point_ids.some((pointId) => pointId !== null) || this.geoToSaveDeviceStatus) &&
        !this.newDeviceSaved
      ) {
        if (this.pointIdQr) {
          this.newDeviceSaved = await this.sendBatteryStatus(this.UUID, position, this.pointIdQr)
          return
        }
        if (this.token) {
          const point = await getPointByToken(this.token)
          if (point && point.id) {
            this.newDeviceSaved = await this.sendBatteryStatus(this.UUID, position, point.id)
            return
          }
        }

        if (this.pointIdGeolocation) {
          this.geoToSaveDeviceStatus = await this.sendBatteryStatus(
            this.UUID,
            position,
            this.pointIdGeolocation
          )
          return
        }
      }
    },

    getLocation() {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
          return reject(new Error('Geolocation not supported'))
        }

        const getLocationOptions = {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 0
        }

        const onSuccess = (pos) => {
          const crd = pos.coords

          const position = {
            lng: crd.longitude, // "-43.101947"
            lat: crd.latitude, //"-22.904369"
            precision: crd.accuracy // "1"
          }
          resolve(position)
        }

        const onError = (err) => {
          console.log(`ERROR(${err.code}): ${err.message}`)
          // Reject the promise with the error
          reject(err)
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError, getLocationOptions)
      })
    },
    handleTerminalFarCancel() {
      this.$nextTick(() => {
        if (this.$refs.tokenSelection) {
          this.$refs.tokenSelection.saveInvalidTransaction(
            'Usuário negou devido a distância',
            this.transactionId
          )
        }
      })
      this.rentProcessingCancelByUserFar()
    },
    handleClosePBox() {
      this.showPBox = false
      this.token = null
      this.transactionId = null
      this.pointName = null
      this.selectBigBoxComponent()
    },
    scrollToPriceTable() {
      this.showPriceTable = !this.showPriceTable
      const element = this.$refs['priceTable']
      if (element) {
        this.$scrollTo(element, 500, {
          easing: 'ease'
        })
      }
    },
    updateBatteryStatus(battery) {
      this.batteryLevel = (battery.level * 100).toFixed(0)
      this.batteryCharging = battery.charging ? 'Yes' : 'No'
    },
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    getModel(info) {
      let model = ''
      if (info.isSamsung) {
        model = 'samsung'
      } else if (info.isiPhone) {
        model = 'apple'
      } else if (info.isiPad) {
        model = 'apple'
      } else if (info.isLinux) {
        model = 'linux'
      }
      return model
    },
    async sendDeviceInfo() {
      let parser = null
      let manufacturer = null
      let model = null
      let browser = null
      let browserVersion = null
      try {
        // console.log(navigator)

        parser = new UAParser(navigator.userAgent || navigator.vendor || window.opera)
      } catch (error) {
        console.log(error)
      }

      if (parser.getDevice().vendor) {
        manufacturer = parser.getDevice().vendor
      }
      if (parser.getDevice() && parser.getDevice().model) {
        model = parser.getDevice().model
      }

      if (parser.getBrowser() && parser.getBrowser().name && parser.getBrowser().version) {
        browser = parser.getBrowser().name
        browserVersion = parser.getBrowser().version
      }

      try {
        await API.post('customer/device_info', {
          device_id: this.UUID,
          manufacturer: manufacturer,
          model: model,
          platform: browser,
          platform_version: browserVersion,
          app_version: VERSION,
          carrier_name: null,
          country_code: null,
          phone_number: null,
          sim_serial_number: null
        })
      } catch (error) {
        console.log(error)
      }
    },
    sendBatteryStatus: async function (deviceId, position, pointId) {
      let params = {
        action: 'registration',
        charge: this.batteryLevel,
        device_id: deviceId,
        precision: position.precision,
        point_id: pointId
      }
      if (position) {
        params.lat = position.lat
        params.long = position.lng
      }
      try {
        const response = await API.put('battery/save_history', params)
        response.data.success && console.log('Salvar status do Aparelho: ' + response.data.success)
        if (response.data.success) {
          this.user.devices = this.user.devices.map((device) => {
            if (device.uuid === deviceId) {
              device.action2_count += 1
              device.point_ids.push(pointId)
            }
            return device
          })
        }
        return response.data.success
      } catch (error) {
        this.handleError(error)
      }
    },
    async getUser(position = null) {
      let url
      if (position && position.lat && position.lng) {
        url = `customer/me/${position.lat}/${position.lng}`
      } else {
        url = 'customer/me'
      }

      try {
        const response = await API.get(url)
        if (!response || !response.data) {
          return
        }

        this.user = response.data
        this.updateLastTransaction()
        setInterval(() => {
          this.updateLastTransaction()
        }, 60000)

        try {
          const response = await axios.get(import.meta.env.VITE_MOBILE_API + 'plans')
          if (response.data) {
            this.plans = response.data
          }
        } catch (error) {
          this.handleError(error)
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    getPlans: async function () {
      try {
        const response = await axios.get(import.meta.env.VITE_MOBILE_API + 'plans')
        if (response.data) {
          this.plans = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    updateLastTransaction: async function () {
      try {
        const response = await API.get('transaction/history?page=1&items_per_page=1')

        if (!response.data) {
          return
        }

        this.lastTransaction = response.data.last_transaction

        let lastTransaction
        if (isProxy(this.lastTransaction)) {
          lastTransaction = toRaw(this.lastTransaction)
        }

        this.lastTransaction = lastTransaction
        this.lastTransaction.last_operation = lastTransaction.last_operation

        let now = new Date().getTime()
        let past = this.lastTransaction.take_time * 1000
        let freeTime = this.lastTransaction.free_time * 1000

        if (now - past > freeTime) {
          this.lastTransaction['on_free_time'] = false
        } else {
          this.lastTransaction['on_free_time'] = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    takeBattery: async function () {
      try {
        this.rentProcessing()
        const response = await this.takeValidatedBattery()

        if (response.data.success) {
          this.transactionId = response.data.transaction_id
          this.getTransactionStatus(this.transactionId)
          this.selectBigBoxComponent()
        } else {
          if (response.data.error) {
            this.rentProcessingError(': ' + response.data.error)
          } else {
            this.rentProcessingError()
          }
        }
      } catch (error) {
        this.rentProcessingError()
        console.log(error)
      }
    },
    async takeValidatedBattery() {
      const params = {
        transaction_id: this.transactionId,
        uuid: this.UUID,
        terminal_far_away: this.terminal.far
      }

      try {
        const url = 'transaction/validation/take_battery'
        return await API.post(url, params)
      } catch (error) {
        console.log(error)
      }
    },

    async saveInvalidTransaction(cancelReason, transactionId = null, tokenS = null) {
      let params = {
        token: this.token ? this.token : tokenS,
        cancel_reason: cancelReason
      }

      if (transactionId) {
        params.transaction_id = transactionId
      }

      try {
        let endpoint = 'transaction/invalid'
        const response = await API.post(endpoint, params)
        return response
      } catch (error) {
        console.log(error)
      }
    },

    getTransactionStatus: async function (id) {
      try {
        const response = await API.get('transaction/status/' + id)

        if (response.data.timeouted) {
          this.rentProcessingError()
        }

        if (!response.data.success && response.data.message) {
          this.rentProcessingError(': ' + response.data.message)
        }
        if (response.data.status === TAKE_BATTERY_SUCCESS) {
          this.rentProcessingSucess('Bateria retirada com sucesso')
          this.$gtag.event('battery_rental', {
            event_category: 'Transação',
            event_label: 'Aluguel de Bateria'
          })
          setTimeout(() => {
            this.updateLastTransaction()
          }, 2000)
        } else if (response.data.status === TAKE_BATTERY_ERROR) {
          this.transactionId = id
          this.showPBox = false
          this.showEvaluationTags = true
          this.pointName = null
          return
        } else if (response.data.status === 'firmware_communication_error') {
          this.rentProcessingError(': Ocorreu um erro ao tentar se comunicar com o terminal')
        } else {
          setTimeout(() => {
            this.getTransactionStatus(id)
            this.pointName = null
          }, 3000)
        }
      } catch (error) {
        console.log(error)
        this.rentProcessingError(': Falha ao processar a retirada')
      }
    },
    resetRentVariables: function () {
      this.modal_error = ''
      this.terminal.campaign = false
      this.terminal.far = false
      this.position = null
      this.user_permission.info_sharing = null
      this.user_permission.info_sharing_anyway = false
      this.user_permission.credit_reservation = null
      this.user_permission.credit_reservation_always = false
    },
    confirmRent() {
      this.rentProcessingStart()
      this.resetRentVariables()

      if (
        this.campaign?.no_payment ||
        this.user.credit_cards.length > 0 ||
        !this.user.is_user_on_demand
      ) {
        this.verifyTerminal()
        return
      }
    },
    async verifyTerminal() {
      if (this.takeType !== 'terminal') {
        return this.creditReservation()
      }

      if (!this.position) {
        try {
          const position = await this.getLocation()
          this.position = toRaw(position)
          console.log('geolocation: ', this.position)
        } catch (error) {
          console.error('Error on geolocation:', error)
        }
      }

      this.completeVerifyTerminal(this.position)
    },
    async completeVerifyTerminal(position) {
      this.rentProcessingVerifyTerminal()

      let params
      try {
        if (position && position.lng && position.lat) {
          params = {
            token: this.token,
            position: {
              lng: position.lng,
              lat: position.lat
            }
          }
        } else {
          params = {
            token: this.token
          }
        }

        const response = await API.post('transaction/check_terminal', params)
        this.terminal.campaign = response.data.campaign
        this.terminal.far = TERMINAL_DISTANCE_UNKNOW

        if (this.position) {
          if (response.data.far_away) {
            this.terminal.far = TERMINAL_DISTANCE_FAR
          } else {
            this.terminal.far = TERMINAL_DISTANCE_NEAR
          }
        }

        if (response.data.far_away) {
          this.rentProcessingTerminalFar()
        } else {
          this.creditReservation()
        }
      } catch (error) {
        console.log(error)
        this.rentProcessingError()
      }
    },
    creditReservation: function () {
      this.rentProcessingPayment()
      if (this.campaing && this.campaign.no_payment) {
        return this.campaignPermission()
      }

      if (
        this.user.user_permission.transaction_permission_granted ===
          USER_INFO_SHARING_PERMISSION_GRANTED_ALWAYS ||
        !this.user.is_user_on_demand
      ) {
        return this.campaignPermission()
      }

      this.rentProcessingPaymentPermission()
    },
    completeCreditReservation: function (ok) {
      this.rentProcessingPayment()
      this.user_permission.credit_reservation_always = this.creditCardReservationAlways

      if (ok) {
        if (this.user_permission.credit_reservation_always) {
          this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_ALLOW_ALWAYS
        } else {
          this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_ALLOW
        }
        this.campaignPermission()
      } else {
        this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_DO_NOT_ALLOW
        this.sendUserPermissions()
      }
    },
    campaignPermission: function () {
      if (this.terminal.campaign) {
        this.handleClosePBox()
        this.rentProcessingCampaingPermission()
      } else {
        this.sendUserPermissions()
      }
    },
    completeCampaignPermission: function (ok) {
      this.user_permission.info_sharing_anyway = this.infoSharingAnyway
      if (ok) {
        if (this.user_permission.info_sharing_anyway) {
          this.user_permission.info_sharing = USER_INFO_SHARING_PERMISSION_GRANTED_ALWAYS
        } else {
          this.user_permission.info_sharing = USER_INFO_SHARING_PERMISSION_GRANTED
        }
      } else {
        this.user_permission.info_sharing = USER_INFO_SHARING_PERMISSION_DENIED
      }
      this.sendUserPermissions()
    },
    sendUserPermissions: async function () {
      try {
        await API.post('transaction/permission', {
          transaction_permission_granted: this.user_permission.credit_reservation,
          info_sharing_permission_granted: this.user_permission.info_sharing
        })
        if (this.user_permission.credit_reservation === CREDIT_CARD_RESERVATION_DO_NOT_ALLOW) {
          this.rentProcessingError(': ' + 'Usuário negou reserva de crédito')
          this.saveInvalidTransaction(null, this.transactionId)
          return
        } else if (this.user_permission.info_sharing === USER_INFO_SHARING_PERMISSION_DENIED) {
          this.rentProcessingError(': ' + 'Usuário negou receber informações do patrocinador')
          this.saveInvalidTransaction(null, this.transactionId)
          return
        }
        this.takeBattery()
      } catch (error) {
        console.log(error)
        this.rentProcessingError()
      }
    },
    showCableTypeSelection: function (cableType) {
      try {
        console.log(cableType)
        this.selected_cable = cableType
      } catch (error) {
        setTimeout(() => {
          console.log(this.showCableTypeSelection)
        }, 500)
      }
    },
    open_tabela_de_precos_modal: function () {
      try {
        this.$refs.tabela_de_precos.show()
      } catch (error) {
        setTimeout(() => {
          this.open_tabela_de_precos_modal()
        }, 500)
      }
    },
    deleteCard: function () {
      if (!this.user.default_card || !this.user.default_card.id) {
        console.error('No selected card to delete.')
        return
      }

      const card_id = this.user.default_card.id

      try {
        API.delete('credit_card/register/' + card_id).then(
          (response) => {
            if (response && response.data && response.data.success) {
              let user = this.user
              if (user && user.credit_cards) {
                user.credit_cards = user.credit_cards.filter((card) => card.id !== card_id)
                user.default_card = null
                this.user = user
                this.selectBigBoxComponent()
              } else {
                console.error("User or user's credit cards are not defined correctly.")
              }
            }
          },
          (error) => {
            this.handleError(error)
          }
        )
      } catch (error) {
        this.handleError(error)
      }
    },
    defaultCard: function () {
      API.put('credit_card/set_default/' + this.selected_card.id).then(
        () => {},
        (error) => {
          this.handleError(error)
        }
      )
    },
    handleError: function (error) {
      if (error.status && error.status === 401) {
        this.$router.push({
          name: 'logout',
          params: {}
        })
      } else {
        console.log(error)
      }
    }
  }
}
