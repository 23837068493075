<div class="mytabs">
  <div>
    <a class="btn-close-map" @click="$router.go(-1)">
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </a>
  </div>
  <h3 class="title">
    <div v-text="$t('history.rent')"></div>
  </h3>
  <div>
    <div class="text-center">
      <BSpinner id="loading-spinner" v-if="spinner" />
    </div>
    <b-card
      tag="article"
      header="featured"
      header-tag="header"
      v-for="element in results"
      :key="element.id"
    >
      <template #header>
        <div class="header-card-items">
          <div>{{ element.elapsed_time }}</div>
          <span>
            <span v-text="$t('history.plan')" v-if="!element.no_plan"></span>
            {{ element.plan_name }}
          </span>
        </div>
      </template>
      <b-card-text>
        <div class="flex-row-sign m-3">
          <font-awesome-icon
            icon="fa-arrow-down"
            class="rounded-circle bg-danger p-2"
            variant="light"
            font-scale="2.5"
          />
          <div class="sign">
            <div style="font-size: large"><strong>{{ element.take_point.name }}</strong></div>
            <div style="font-size: small"><strong>{{ element.take_point.address }}</strong></div>
            <div style="font-size: small; color: rgb(117, 117, 117)">
              <strong>{{ element.take_date }}</strong>
            </div>
          </div>
        </div>
        <div class="flex-row-sign m-3" v-if="element.return_time !== 0">
          <font-awesome-icon
            icon="fa-arrow-up"
            class="rounded-circle bg-info p-2"
            variant="light"
            font-scale="2.5"
          />
          <div class="sign">
            <div style="font-size: large"><strong>{{ element.return_point.name }}</strong></div>
            <div style="font-size: small"><strong>{{ element.return_point.address }}</strong></div>
            <div style="font-size: small; color: rgb(117, 117, 117)">
              <strong>{{ element.return_date }}</strong>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
  <div class="text-center">
    <b-button v-if="next_page" @click="get_history" variant="danger">
      <div v-text="$t('history.load_more')"></div>
    </b-button>
  </div>
</div>
