<template>
  <b-modal
    v-if="plans"
    ref="priceTable"
    hide-header
    hide-footer
    @hide="hidePriceTableModal"
  >
    <PriceTable
      :show-price-table="true"
      :no-shadow="true"
      @close="hidePriceTableModal"
    />
  </b-modal>
</template>

<script>
import '@/main.scss'
import axios from 'axios'
import * as Sentry from '@sentry/vue'

export default {
  name: 'PriceTableModal',

  components: {
    PriceTable: () => import('@/components/PriceTable.vue')
  },

  data() {
    return {
      plans: null,
      plan_4_hours: null,
      plan_daily: null,
      mobile_api_url: import.meta.env.VITE_MOBILE_API
    }
  },

  async mounted() {
    await this.getPlans()
    this.openPriceTableModal()
  },

  methods: {
    openPriceTableModal() {
      const tryOpenModal = () => {
        try {
          this.$refs.priceTable.show()
        } catch (error) {
          setTimeout(tryOpenModal, 200)
        }
      }
      tryOpenModal()
    },

    hidePriceTableModal() {
      this.$emit('hidePriceTableModal')
    },

    async getPlans() {
      try {
        const response = await axios.get(this.mobile_api_url + 'plan')
        if (response.data) {
          this.plans = response.data
          this.plan_4_hours = this.plans.on_demand.prices.find(
            (price) => price.internal_ref === 'four_hours'
          )
          this.plan_daily = this.plans.on_demand.prices.find(
            (price) => price.internal_ref === 'daily'
          )
          return
        }
        console.log('Failed to get plans')
        Sentry.captureMessage(`Failed to get plan ${JSON.stringify(response)}, calling #getPlans()`)
      } catch (error) {
        Sentry.captureException(error)
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.stay-same-line {
  overflow: hidden;
  white-space: nowrap;
}

.amount-description-color {
  color: $color-energy-orange;
  display: inline-block;
}

.footnote {
  font-size: 12px;
  margin-top: 13px;
}
</style>