<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <div
      v-if="showTokenSelection"
      class="select-card select-card d-flex flex-column"
      :class="{ 'border-danger': success_val === false }"
    >
      <!-- QR scanner-off block begin -->
      <div v-if="cableType != null && !qr_code_on">
        <!-- Titles -->
        <h3
          class="page-title text-center pt-2 qr-title"
          v-text="$t('home.take.qr_code_title_1')"
        />
        <p
          class="page-text text-center my-1 mx-2 qr-text"
          v-text="$t('home.take.qr_code_text')"
        />

        <!-- OR code image and insert qr code input button -->
        <div>
          <!-- Scan QR code button -->
          <div class="text-center mt-3">
            <BButton
              v-if="cableType != null && !qr_code_on"
              :class="{
                'btn btn-scan token_buttons': success_val !== null,
                'btn btn-scan token_buttons': success_val === null,
                'disabled-button': !allowEdit || showSpinner
              }"
              variant="primary"
              @click="scanQr()"
            >
              <div class="qr-button">
                <img
                  src="../assets/images/qr_code.png"
                  alt="QR Code"
                  class="img-button"
                >
                <div class="qr-text-button">
                  <p class="qr-text">
                    <strong>{{ $t('home.take.scan_qr') }}</strong>
                  </p>
                  <p class="qr-text">
                    <strong>{{ $t('home.take.scan_qr2') }}</strong>
                  </p>
                </div>
              </div>
            </BButton>
          </div>
        </div>
      </div>
      <!-- QR scanner-off block end -->

      <!-- QR scanner-on block begin -->
      <div
        v-if="qr_code_on"
        id="qr_div"
      >
        <!--Title -->
        <h3
          class="page-title text-center pt-2 qr-title"
          v-text="$t('home.take.qr_code_title_2')"
        />
        <qrcode-stream
          @detect="onQrDecode"
          @init="onQrInit"
        >
          <!--Scanner -->
          <img
            id="qr-scanner"
            src="../assets/images/qr_fundo.png"
            alt=""
          >
        </qrcode-stream>
        <!-- Close button -->
        <div @click="closeScanQr">
          <font-awesome-icon
            class="btn-close-qr"
            :icon="['fas', 'rectangle-xmark']"
          />
        </div>
        <p
          class="page-text text-center my-2 mx-2 qr-text"
          v-text="$t('home.take.type_token')"
        />
      </div>
      <!--QR scanner-on block end -->
      <p class="page-text text-center mt-2 mb-0">
        {{ 'Ou inserir manualmente:' }}
      </p>
      <b-form-group v-if="cableType != null">
        <b-form-input
          v-model="token"
          :class="['token_input_2', 'mt-3', { 'border-danger': success_val === false }]"
          style="font-size: 16px;"
          autocomplete="off"
          type="text"
          maxlength="4"
          :placeholder="$t('home.take.terminal_token')"
          :disabled="!allowEdit || showSpinner"
        />
      </b-form-group>
      <!-- Take battery button -->
      <div class="text-center">
        <BButton
          v-if="cableType != null"
          variant="primary"
          :class="{
            'token_buttons btn-primary mt-3 my-3': success_val !== null,
            'token_buttons btn-primary mt-3': success_val === null,
            'disabled-button': !token || !allowEdit || showSpinner || disableButton
          }"
          :disabled="!token || !allowEdit || disableButton"
          @click="validateToken()"
        >
          {{ $t('home.take.take_battery') }}
        </BButton>
      </div>

      <!-- spinner -->
      <div
        v-if="showSpinner"
        class="text-center"
      >
        <b-spinner id="loading-spinner" />
      </div>

     
      <!-- agreed price terms -->
      <div
        class="agreement-container mt-2"
      >
        <p
          class="terms-text"
          v-text="$t('home.take.agreed')"
        />
        <router-link
          to="#"
          class="terms-text mx-1"
          @:click="handleShowTablePrice"
        >
          <a v-text="$t('home.take.price_table')" />
        </router-link>
      </div>

      <!-- errors -->
      <div v-if="success_val !== null">
        <div class="message-fail">
          <p>{{ validationError }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import * as Sentry from '@sentry/vue'
import API from '@/services/api'
import { VALIDATION_SUCCESS } from '@/constants.js'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'TokenSelection',
  components: {
    QrcodeStream
  },
  props: {
    cableType: {
      type: Number,
      default: null
    },
    confirmRent: {
      type: Function,
      default: () => {}
    },
    showTokenSelection: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    saveInvalidTransaction: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['token', 'handleShowPriceTable', 'transactionId', 'pointName', 'takeType'],
  data() {
    return {
      qr_code_on: false,
      token: '',
      validationError: null,
      success_val: null,
      showSpinner: false,
      takeType: 'terminal',
      disableButton: false
    }
  },
  computed: {
    displayToken() {
      return this.token || this.$t('home.take.terminal_token')
    }
  },
  watch: {
    token(newValue) {
      this.$emit('token', newValue)
      this.validationError = null
      this.success_val = null
      this.disableButton = false
    },
    showTokenSelection(newValue) {
      if (!newValue) {
        this.validationError = null
        this.success_val = null
      }
    },
    showSpinner(newValue) {
      this.showSpinner = newValue
    },
    success_val(newValue) {
      this.success_val = newValue
    },
    validationError(newValue) {
      this.validationError = newValue
    }
  },

  mounted() {
    this.token = sessionStorage.getItem('tokenTerminal')
    let terminalToken = localStorage.getItem('terminal_token')
    if (terminalToken) {
      this.token = terminalToken
      localStorage.removeItem('terminal_token')
      localStorage.removeItem('point_name')
    }
  },
  methods: {
    onQrDecode: function (results) {
      for (const dectected of results) {
        if (!dectected.rawValue || !dectected.rawValue.split('qr/')[1]) {
          const tempToken = dectected.rawValue.split('qr/')[1]
          if (!this.localValidationToken(tempToken)) {
            this.validateResponse(tempToken)
            return
          } 
        }
      }
      // para enviar caso o qr code esteja errado
      this.validateResponse(results[0].rawValue)
    },
    validateResponse (tempToken) {
      this.token = tempToken
      this.qr_code_on = false
      this.validateToken()
    },
    scanQr: function () {
      this.qr_code_on = true
    },
    closeScanQr: function () {
      this.qr_code_on = false
    },
    onQrInit: async function (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.handleError('ERROR: you need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          this.handleError('ERROR: no camera on this device')
        } else if (error.name === 'NotSupportedError') {
          this.handleError('ERROR: secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          this.handleError('ERROR: is the camera already in use?')
        } else if (error.name === 'OverconstrainedError') {
          this.handleError('ERROR: installed cameras are not suitable')
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.handleError('ERROR: Stream API is not supported in this browser')
        }
      }
    },
    handleShowTablePrice() {
      this.$emit('handleShowPriceTable')
    },

    isAlphabetic (str) {
      const result = [...str]
        .every(char => (char >= 'a' && char <= 'z') ||
                        (char >= 'A' && char <= 'Z'))
      return result
    },
    isNumeric (str) {
      const result = [...str]
        .every(char => (char >= '0' && char <= '9'))
      return result
    },

    localValidationToken (token) {
      if (token === null || token === undefined) {
        return this.$t('home.take.qr_scan_fail_backend')
      } else if (token.length === 0) {
        return this.$t('home.take.inform_token')
      } else if (
        !this.isAlphabetic(token) &&
        !this.isNumeric(token)
      ) {
        return this.$t('home.take.token_contain')
      } else if (token.length !== 4) {
        return this.$t('home.take.token_size')
      } else if (this.isAlphabetic(token)) {
        console.log('charger')
        this.takeType = 'charger'
        return false
      } else {
        return false
      }
    },

    async validateToken() {
      this.showSpinner = true
      this.disableButton = true
      this.success_val = null
      this.validationError = null
      const error = this.localValidationToken(this.token)
      

      if (error) {
        console.log(error)
        try {
          const response = await this.saveInvalidTransaction(error, null, this.token)
          this.validationError = error
          this.success_val = false
          this.showSpinner = false
          this.disableButton = true
          return response
        } catch (error) {
          this.validationError = error
          this.success_val = false
          this.showSpinner = false
          this.disableButton = true
          return Sentry.captureException(error)
        }
      }
      this.disableButton = false

      try {
        const response = await this.validateTerminalToken()

        if (!response || !response.data) {
          this.showSpinner = false
          this.success_val = false
          this.validationError = 'Erro genérico!'
        }

        if (!response.data.success || !response.data.transaction_id) {
          this.success_val = false
          if (response.data.error) {
            console.log(response.data.error)
            this.validationError = response.data.error
          } else {
            this.validationError = 'Erro genérico!'
          }
          this.showSpinner = false
          return
        }

        this.$emit('transactionId', response.data.transaction_id)
        this.$emit('pointName', response.data.point_name)
        this.$emit('takeType', this.takeType)
        try {
          if (response.data.success && response.data.status === VALIDATION_SUCCESS) {
            this.showSpinner = false
            this.confirmRent()
          } else {
            const error = 'Transaction not validated'
            console.log(error)
            this.saveInvalidTransaction(error, response.data.transaction_id)
            this.showSpinner = false
          }
        } catch (error) {
          console.log(error)
          this.saveInvalidTransaction(error, response.data.transaction_id)
          this.showSpinner = false
        }
      } catch (error) {
        console.log(error)
        this.validationError =
          'Problema de comunicação com a estação de bateria. Tente novamente em instantes.'
        this.saveInvalidTransaction(error)
        this.showSpinner = false
        return
      }
      this.showSpinner = false
    },
    async validateTerminalToken() {
      let endpoint = 'transaction/validation'

      try {
        const response = await API.post(endpoint, {
          token: this.token,
          cable_type: this.cableType,
          custom_link: null //this.campaign && this.campaign.custom_link ? this.campaign.custom_link : null
        })
        return response
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.token_buttons {
  max-width: 150px;
  width: 100%;
  display: block;
  margin: 0 auto;
  white-space: nowrap;
}

.btn-scan {
  background-color: $color-energy-light-blue !important;
  max-width: 230px;
  align-items: center;
}

#qr-image-back {
  width: 100%;
  height: 100%;
}

#qr_div {
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

#qr-scanner {
  width: 95%;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.btn-close-qr {
  font-size: 2rem;
  z-index: 1000; /* Ensure it's on top of other elements */
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 1rem;
  cursor: pointer;
  padding: 5px; /* Optional: add padding for a larger clickable area */
  // border-radius: 50%; /* Optional: make it circular */
}

.qr-title {
  font-weight: bold;
  color: #000000;
}

.qr-text {
  color: #000000;
  width: 95%;
}

.qr-input-container {
  background-color: $color-energy-orange;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 3%;
  max-width: 50%;
  margin: auto;
}

.token_input_2 {
  max-width: 150px;
  margin: 0 auto;
  text-align: left;
  border: 1px solid #000000;
  box-shadow: #333;
  font-size: 0.875rem;
  text-align: center;
}

.agreement-container {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 0.9em;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 0.3rem;

  /* Optional: Adds some space at the bottom */
  p {
    margin: 0;
    /* Reset margin */
  }
}

.message-fail {
  width: calc(100% + 1rem);
  position: relative;
  bottom: -0.5rem;
  left: -0.5rem;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  p {
    margin: 0;
  }
}

.border-danger {
  border: 2px solid red;
}

.token-display {
  font-size: 2rem;
  text-align: center;
  color: white;
  font-weight: bold;
  p {
    margin: 0;
    /* Reset margin */
  }
}

.disabled-button {
  background-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.img-button {
  width: 80px;
  height: 80px;
  border: 5px solid $color-energy-orange;
  margin: 1px 10px 1px 1px;
  border-radius: 8px;
}

.qr-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
}

.qr-text-button {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  font-size: 25px;
}

.qr-text {
  margin: 2px;
  font-weight: 900;
}

.token_input_2::placeholder {
  color: #999; /* Light gray color */
  opacity: 0.7; /* Slightly transparent */
}

#loading-spinner {
  color: $color-energy-orange !important;
  margin: 10px;
}

</style>
