<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <!-- Box transition -->
    <div
      v-if="showUserDefaults"
      class="indv-card select-card"
      :style="backgroundStyle"
    >
      <div class="bv-example-row">
        <BRow>
          <!-- Left Image with transition -->
          <Transition
            name="fade-element"
            mode="out-in"
          >
            <!-- Image transition -->
            <BCol
              key="image"
              cols="3"
              class="d-flex justify-content-center align-items-center"
            >
              <slot name="left-content" />
            </BCol>
          </Transition>

          <!-- Middle Text with transition -->
          <Transition
            name="fade-element"
            mode="out-in"
            appear
          >
            <!-- Text transition -->
            <BCol
              key="text"
              cols="6"
              class="d-flex justify-content-left align-items-center"
            >
              <div class="fw-normal text-lighter text-container">
                <p
                  class="page-text no-margin"
                  v-text="mainText"
                />
                <p
                  class="page-title fw-bold d-inline-block text-lighter no-margin"
                  v-text="subText"
                />
                <p
                  class="terms-text d-inline-block no-margin"
                  v-text="termsText"
                />
              </div>
            </BCol>
          </Transition>

          <!-- Right Button -->
          <Transition
            v-if="allowEdit"
            name="fade-element"
            mode="out-in"
            appear
          >
            <BCol
              key="text"
              cols="2"
              class="btn d-flex justify-content-end"
            >
              <button
                class="btn d-flex justify-content-end"
                @click="handleClick"
              >
                <font-awesome-icon
                  class="btn-icon-arrow fa-2x"
                  icon="fa-solid fa-chevron-down"
                />
              </button>
            </BCol>
          </Transition>
        </BRow>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'DefaultTileHome',
  props: {
    mainText: {
      type: String,
      required: false,
      default: ''
    },
    subText: {
      type: String,
      required: false,
      default: ''
    },
    termsText: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },
    showUserDefaults: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    backgroundStyle() {
      return this.backgroundImage ? { backgroundImage: `url(${this.backgroundImage})` } : {}
    }
  },
  methods: {
    handleClick() {
      this.$emit('clicked')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.indv-card {
  justify-content: center;
  height: 100px;
  margin: 5px auto !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px !important;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    margin: 10px auto !important;
    text-align: left;
    justify-content: center;
  }

  @media (max-width: 576px) {
    margin: 10px auto !important;
    text-align: left;
    justify-content: center;
  }
}

.text-container {
  padding: 0;
  margin: 0;
}

.btn-icon-arrow {
  margin: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row > .col-4,
.row > .col-6,
.row > .col-2 {
  flex: 1 1 auto;
}
// /* Transition for the entire box */
// .fade-box-enter-active, .fade-box-leave-active {
//   transition: opacity 1s ease;
// }

// .fade-box-enter, .fade-box-leave-to {
//   opacity: 0;
// }

// /* Transition for individual elements */
// .fade-element-enter-active,
// .fade-element-leave-active {
//   transition: opacity 0.9s ease, transform 0.9s ease;
// }

// .fade-element-enter,
// .fade-element-leave-to {
//   opacity: 0;
//   transform: translateY(10%);
// }

// /* Sequential transition for image and text */
// .fade-element-leave-active:nth-child(1) { /* Image fades first */
//   transition-delay: 0.6s;
// }

// .fade-element-leave-active:nth-child(2) { /* Text fades second */
//   transition-delay: 0.9s;
// }

// /* Fade-in sequence for the next set */
// .fade-element-enter-active:nth-child(1) { /* Box enters first */
//   transition-delay: 1.5s;
// }

// .fade-element-enter-active:nth-child(2) { /* Text enters second */
//   transition-delay: 2s;
// }
</style>
