export default function getEventsBigComponents(component) {
  switch (component) {
    case 'priceTable':
      return {
        close: () => this.scrollToPriceTable()
      }
    case 'creditCardRegistration':
      return {
        handleUser: (user) => {
          this.user = user
          this.selectBigBoxComponent()
        }
      }
    case 'deleteCreditCard':
      return {
        confirm: () => this.deleteCard(),
        cancel: () => this.selectBigBoxComponent()
      }
    case 'selectCableType':
      return {
        cableType: (cableType) => {
          this.cableType = cableType
          this.selectBigBoxComponent()
        }
      }
    case 'tokenSelection':
      return {
        token: (token) => (this.token = token),
        transactionId: (transactionId) => {
          this.transactionId = transactionId
        },
        pointName: (pointName) => {
          this.pointName = pointName
          this.selectBigBoxComponent()
        },
        takeType: (takeType) => {
          this.takeType = takeType
        },
        handleShowPriceTable: () => this.scrollToPriceTable()
      }
    default:
      return console.log('Component not found')
  }
}
