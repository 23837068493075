<template>
  <router-view />
</template>

<script>
import axios from 'axios'
import getPointByToken from '@/services/getPointName'

export default {
  name: 'app',
  data() {
    return {
      language: 'pt-BR',
      external: false,
      token: null,
      terminalToken: null,
      pointName: null,
      pathname: null,
      search: null,
      publicPages: ['/login-social', '/login-email', '/code-validation', '/sign-up', '/maps'],
      privatePages: ['/home', '/history']
    }
  },

  async created() {
    this.pathname = window.location.pathname
    this.search = window.location.search

    if (this.search.includes('?token=')) {
      this.token = this.search.split('?token=')[1].split('&')[0]
    } else if (this.pathname.includes('qr/')) {
      this.terminalToken = this.pathname.split('qr/')[1]
      this.pathname = this.pathname.split('qr/')[0]
    }

    if (this.terminalToken) {
      const point = await getPointByToken(this.terminalToken)
      if (point && point.success) {
          this.pointName = point.name
          localStorage.setItem('terminal_token', this.terminalToken)
          localStorage.setItem('point_name', point.name)
          localStorage.setItem('point_id', point.id)
        }
      // const point = await getPointByToken(this.terminalToken)
      // this.pointName = point.name
      // this.$gtag.event('qr_code_scan', {
      //   event_category: 'Engajamento',
      //   event_label: 'Acesso pelo qr code',
      //   point_name: this.pointName,
      //   token: this.terminalToken,
      //   non_interaction: true
      // })
    }

    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')

      if (this.privatePages.includes(this.pathname)) {
        return this.$router.push(this.pathname)
      } else {
        return this.$router.push('/home')
      }
    } 

    if (this.token) {
      axios.defaults.headers.common['Authorization'] = this.token
      return this.$router.push('/home')
    }
    
    if (this.publicPages.includes(this.pathname)) {
      return this.$router.push(this.pathname)
    }

    this.$router.push('/login-social')
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: main;
  src: url('@/assets/fonts/main.otf');
}

html,
body {
  margin: 0 !important;
  font-family: main;
  letter-spacing: 1px;
}
</style>
