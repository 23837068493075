<div id="home-master-div" :style="{ background: 'white', color: '#424242' }">
  <SideBar :nome="name" :email="email" />
  <BSpinner id="loading-spinner" v-if="!lastTransaction" />

  <CheckOutScreen
    v-if="user && lastTransaction && lastTransaction.last_operation === 'retirar'"
    :name="name"
    :email="email"
    :user="user"
    :lastTransaction="lastTransaction"
    :campaign="campaign"
    :cableType="cableType"
    :showPriceTable="showPriceTable"
    @scrollToPriceTable="scrollToPriceTable"
    
  />

  <div
    v-else-if="lastTransaction && lastTransaction.last_operation != 'retirar'"
    class="default-container"
  >
    <div class="default-container-start">
      <EvaluationBox v-if="showEvaluationTags" :transactionId="transactionId" />

      <DefaultTile
        v-if="user && user.default_card"
        :showDefaultTile="showCreditCardDeleteBox"
        :title="$t('home.take.delete_title')"
        :text="$t('home.take.delete_text') + ' ' + user.default_card.number"
        :showConfirmButton="true"
        :showCancelButton="true"
        :confirmButtonText="'CONFIRMAR'"
        :cancelButtonText="'CANCELAR'"
        @confirm="deleteCard"
        @cancel="() => this.showCreditCardDeleteBox = false"
      />
      
      <DefaultTile
        :showDefaultTile="showPBox"
        :title="pBoxTitle"
        :text="pBoxText"
        :showCancelButton="showPBoxCancelButton"
        :cancelButtonText="pBoxCancelButtonText"
        :showConfirmButton="showPBoxConfirmButton"
        :confirmButtonText="pBoxConfirmButtonText"
        :showCheckBox="showCheckboxPBox"
        :checkBoxText="pBoxCheckBoxText"
        :showSpinner="showPBoxSpinner"
        :secondsToClose="pBoxSecondsToClose"
        :showLink="pBoxShowLink"
        @handleOpenTable="scrollToPriceTable"
        @checkBoxValue="pBoxCheckBoxValue"
        @confirm="pBoxButtonConfirmation"
        @cancel="pBoxButtonCancel"
        @close="handleClosePBox"
      />
      <div
        :ref="selectedBigBoxComponent.reference"
        v-if="showBigBoxComponent && selectedBigBoxComponent"
      >
        <component
          :is="selectedBigBoxComponent.name"
          v-bind="getPropsBigComponents(selectedBigBoxComponent.reference)"
          v-on="getEventsBigComponents(selectedBigBoxComponent.reference)"
        />
      </div>
      
    </div>

    <div class="default-container-end">

      <component
        v-for="component in showMiniBoxComponentList"
        :key="component.id"
        :is="component.name"
        v-bind="getPropsMiniComponents(component.reference, user, cableType)"
        v-on="getEventsMiniComponents(component.reference)"
      >

        <template #left-content v-if="component.reference === 'map'">
          <font-awesome-icon class="fa-3x" icon="map-location-dot" />
        </template>
        <template #left-content v-if="component.reference === 'priceTable'">
          <font-awesome-icon class="fa-3x" icon="money-bill" />
        </template>
        <template #left-content v-if="component.reference === 'userCreditCard'">
          <font-awesome-icon class="fa-3x" :icon="['fab', 'cc-mastercard']" />
        </template>
        <template #left-content v-if="component.reference === 'cableType'">
          <font-awesome-icon class="fa-3x" icon="plug" />
        </template>
        <template #left-content v-if="component.reference === 'token'">
          <font-awesome-icon :style="{color: '#424242'}" class="fa-3x" icon="location-dot" />
        </template>

      </component>

      <div ref="priceTable">
        <component
            v-if="showPriceTable"
            is="PriceTable"
            v-bind="getPropsBigComponents('priceTable')"
            v-on="getEventsBigComponents('priceTable')"
        />
      </div>
      
    </div>
  </div>

  <!-- <div class="whatsapp-footer">
    <a :href="$t('login.whatsapp_msg')" target="_blank">
      <font-awesome-icon class="whatsapp-icon" :icon="{ prefix: 'fab', iconName: 'whatsapp' }" />
    </a>
  </div> -->
</div>
