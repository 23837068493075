import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USB } from '@/constants.js'

export default function getPropsMiniComponents(component, user, cableType) {
  switch (component) {
    case 'map':
      return {
        showUserDefaults: true,
        subText: this.$t('login.find_us')
      }
    case 'priceTable':
      return {
        showUserDefaults: true,
        subText: this.$t('home.take.price_table')
      }
    case 'userCreditCard':
      return {
        showUserDefaults: user && user.default_card && user.default_card.number.length === 4,
        mainText: this.$t('home.take.user_card'),
        subText: this.$t('home.take.card_ending') + ' ' + user.default_card.number,
        allowEdit: !this.showPBox
      }
    case 'cableType':
      return {
        showUserDefaults: cableType !== null,
        subText: defaultCableText(cableType),
        termsText: this.$t('home.take.user_cable_notice_2'),
        allowEdit: !this.showPBox
      }
    case 'token':
      return {
        showUserDefaults:
          this.token && this.token !== '' && this.transactionId !== null && this.pointName !== null,
        mainText: 'Local da Retirada',
        subText: this.pointName + ' - ' + this.token,
        allowEdit: !this.showPBox
      }
    default:
      return console.log('Component not found')
  }
}

function defaultCableText(cableType) {
  if (cableType === CABLE_TYPE_LIGHTNING) {
    return 'Apple Lightning'
  } else if (cableType === CABLE_TYPE_MICRO) {
    return 'Micro USB'
  } else if (cableType === CABLE_TYPE_USB) {
    return 'USB-C'
  }
  return null
}
